<template>
  <div class="container py-3" id="checkout-thankyou">
    <b-card :title="$t('booking.guide.payment') + paymentMethodName" class="checkout--card">
      <div class="text-center">
        <div class="pb3">
          <p>{{ $t('booking.paymentValue') }}</p>
          <h3 class="bold mt1" id="finalPrice">
            {{ $n(price, 'currency', 'en-SG') }}
          </h3>
        </div>

        <div class="pt3 pb-3">
          <div class="mb-3" v-show="!$device.isDesktop">
            <a
              @click="redirectClicked"
              :href="actions.deeplink_redirect"
              class="btn btn-primary btn-block"
              >{{ $t('booking.payWithGopay') }}
            </a>
          </div>
          <div v-show="$device.isDesktop || tappedRedirect">
            <p v-show="tappedRedirect">
              {{ $t('booking.scanCode') }}
            </p>
            <p v-show="!tappedRedirect">{{ $t('booking.scanCodeM') }}</p>
            <img :src="actions.qr_url" style="height: 400px; width: 400px;" />
          </div>
        </div>
        <div class="pt3">
          <h3 class="bold primary mt1">{{ orderNumber }}</h3>
        </div>
      </div>
    </b-card>
    <b-card :title="$t('booking.guide.payment') + paymentMethodName" class="mt-3 checkout--card">
      <!--    <b-card :title="'Petunjuk Pembayaran via ' + bookingOrder.jenis_transfer" class="mt-3">-->
      <div class="clearfix mb-20"></div>
      <ol class="mt-3" v-if="!$device.isDesktop">
        <li>{{ $t('booking.guide.gopay.msg1') }}</li>
        <li>{{ $t('booking.guide.gopay.msg2') }}</li>
        <li>{{ $t('booking.guide.gopay.msg3') }}</li>
        <li>{{ $t('booking.guide.gopay.msg4') }}</li>
      </ol>
      <ol class="mt-3" v-else>
        <li>{{ $t('booking.guide.gopayM.msg1') }}</li>
        <li>{{ $t('booking.guide.gopayM.msg2') }}</li>
        <li>{{ $t('booking.guide.gopayM.msg3') }}</li>
        <li>{{ $t('booking.guide.gopayM.msg4') }}</li>
      </ol>
      <div class="col-md-12 col-xs-12 col-sm-12 mt-5">
        <!--        <router-link-->
        <!--          :to="`/mybooking/detail?type=BOOKING&uuid=${bookingOrder.uuid}`"-->
        <!--          class="btn btn-primary&#45;&#45;outline btn-block"-->
        <!--          >{{ $t('thankyouPage.viewBookButton') }}-->
        <!--        </router-link>-->
        <router-link :to="viewMybookingUrl" class="btn btn-primary--outline btn-block"
          >{{ $t('general.btn.viewBook') }}
        </router-link>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'permata',
  props: [
    'paymentMethodName',
    'price',
    'virtualAccountNumber',
    'viewMybookingUrl',
    'orderNumber',
    'actions',
  ],
  components: {},
  computed: {
    ...mapState({
      bookingOrder: state => state.v2.booking.bookingOrder,
    }),
  },
  data: () => ({
    tappedRedirect: false,
  }),
  methods: {
    redirectClicked() {
      this.tappedRedirect = true;
    },
  },
};
</script>

<style scoped></style>
